<template lang="pug">
.shopping-cart
  ClientOnly

    .toggle-button(@click="toggleShoppingCart")
      fa(:icon="['fa', 'cart-shopping']")

    .close-overlay(
      v-if="shoppingCartOpen",
      @click="toggleShoppingCart"
    )

    .shopping-cart-popup(:class="{ open: shoppingCartOpen }")
      .header
        .toggle-button(@click="toggleShoppingCart")
          fa(:icon="['fa', 'close']")

        h2 Seu carrinho

      .items

        .item(
          v-for="item in items"
          :key="item.id"
        )
          NuxtLink.thumbnail(
            :to="{ name: 'ecommerce-product-variant', params: { slug: item.productVariantSlug } }"
            @click="toggleShoppingCart"
          )
            CdnImage(
              :src="item.thumbnail",
              :alt="item.name",
              :width="80",
              :height="80",
              :quality="65"
            )

          NuxtLink.description(
            :to="{ name: 'ecommerce-product-variant', params: { slug: item.productVariantSlug } }"
            @click="toggleShoppingCart"
          )
            h3 {{ item.name }}

            .price R$ {{ centsToString(item.price) }}

          .quantity
            .quantity-button(@click="decreaseQuantity(item)")
              fa(:icon="['fa', 'minus']")

            .quantity-value
              | {{ item.quantity }}

            .quantity-button(@click="increaseQuantity(item)")
              fa(:icon="['fa', 'plus']")

      .total
        .total-label
          | Total:

        .total-value
          | R$ {{ centsToString(total) }}

      .actions
        Button.action(label="Limpar", plain, @click="clear")

        Button.action(
          label="Comprar",
          :icon="['fa', 'cart-shopping']",
          :to="{ name: 'ecommerce-checkout' }",
          @click="toggleShoppingCart",
          :disabled="items.length == 0 || cart.loading"
        )
</template>
<script setup lang="ts">
import type { CartItemSummary } from '~/services/ecommerce/cartService'
import { useCart } from '~/store/ecommerce/cart'

interface ShoppingCartItem {
  id: string
  productVariantId: number
  productVariantSlug: string
  name: string
  price: number
  quantity: number
  thumbnail: string
}

const shoppingCartOpen = ref(false)
const clearingCart = ref(false)

const closeShoppingCart = () => {
  shoppingCartOpen.value = false
}

const toggleShoppingCart = () => {
  shoppingCartOpen.value = !shoppingCartOpen.value
}

const cart = useCart()
const notifications = useNotifications()

const items = computed<ShoppingCartItem[]>((): ShoppingCartItem[] => {
  return cart.items.map((item) => {
    return {
      id: item.id,
      productVariantId: item.productVariant.id,
      productVariantSlug: item.productVariant.slug,
      name: item.productVariant.name,
      price: item.productVariant.price,
      quantity: item.quantity,
      thumbnail: item.productVariant.media[0].fileName,
    }
  })
})

const total = computed(() => {
  return items.value.reduce((total, item) => {
    return total + item.price * item.quantity
  }, 0)
})

const increaseQuantity = (item: ShoppingCartItem) => {
  cart.updateItem(item.id, item.quantity + 1)
}

const decreaseQuantity = (item: ShoppingCartItem) => {
  cart.updateItem(item.id, item.quantity - 1)
}

const clear = async () => {
  clearingCart.value = true

  try {
    await cart.clearCart()
  } catch(e) {
    notifications.error('Não foi possível limpar o carrinho')
  } finally {
    clearingCart.value = false
  }
}

onDocumentKeydown('Escape', closeShoppingCart)
</script>
<style scoped lang="sass">
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

$border-color: #f4f4f4

.shopping-cart

  .close-overlay
    position: fixed
    z-index: 99
    top: 0
    left: 0
    width: 100vw
    height: 100vh

  .toggle-button
    cursor: pointer
    display: inline-flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    border-radius: 50%
    font-size: 20px
    user-select: none

    &:hover
      background-color: #f4f4f4

  .shopping-cart-popup
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    position: fixed
    z-index: 100
    top: 0
    right: -416px
    width: 400px
    height: 100vh
    background-color: #fff
    box-shadow: 0 0 16px rgba(160, 100, 100, 0.2)
    transform: translateX(0)
    transition: transform 0.3s ease
    text-align: left

    &.open
      transform: translateX(-416px)

    @include mobile
      width: 100%
      right: -120%

      &.open
        transform: translateX(-120%)

    .header
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: center
      gap: 8px
      padding: 16px 8px
      border-bottom: 1px solid $border-color

      h2
        margin: 0

    .items
      flex-grow: 1
      overflow-y: auto

      .item
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: stretch
        border-bottom: 1px solid $border-color

        .thumbnail
          flex-grow: 0
          width: 112px
          height: 112px
          padding: 16px

          img
            width: 80px
            height: 80px
            object-fit: contain

        .description
          display: block
          flex-grow: 1
          padding: 16px

          h3
            margin: 0
            font-size: 14px
            font-weight: bold

        .quantity
          display: flex
          justify-content: flex-start
          align-items: center
          flex-grow: 0
          padding: 16px

          .quantity-button
            cursor: pointer
            display: inline-flex
            justify-content: center
            align-items: center
            width: 32px
            height: 32px
            border-radius: 50%
            font-size: 20px

            &:hover
              background-color: #f4f4f4

          .quantity-value
            margin: 0 16px
            font-size: 16px
            font-weight: bold

    .total
      display: flex
      justify-content: space-between
      align-items: center
      padding: 16px 32px
      background-color: $border-color

      .total-label
        font-size: 16px
        font-weight: bold

      .total-value
        font-size: 16px

    .actions
      display: flex
      justify-content: stretch
      align-items: center
      gap: 16px
      padding: 32px
      border-top: 1px solid $border-color

      .action
        flex-grow: 1

</style>
